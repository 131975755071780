import React from "react";
import styles from "./style.module.scss";
import { Logo } from "../../assets";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle } from "../../styles/styledComponent";
import { PatientIntakeForm } from "../../components/parts/forms";

const Home = () => {
  return (
    <div className={styles.homePage}>
      <div className={styles.siteLogoContainer}>
        <img src={Logo} alt="Aurora Clinic" />
      </div>
      <div className={styles.pageTitleContainer}>
        <Container>
          <Row>
            <Col>
              <SectionTitle>PATIENT INTAKE FORM</SectionTitle>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.formContainer}>
        <Container>
          <Row>
            <Col>
              <PatientIntakeForm />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Home;
