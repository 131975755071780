export const canadaProvinceData = [
  {
    value: "AB",
    name: "AB",
  },
  {
    value: "BC",
    name: "BC",
  },
  {
    value: "ON",
    name: "ON",
  },
  {
    value: "QC",
    name: "QC",
  },
  {
    value: "NS",
    name: "NS",
  },
  {
    value: "NB",
    name: "NB",
  },
  {
    value: "MB",
    name: "MB",
  },
  {
    value: "SK",
    name: "SK",
  },
  {
    value: "PE",
    name: "PE",
  },
  {
    value: "NL",
    name: "NL",
  },
];

export const sexData = [
  {
    value: "Male",
    name: "Male",
  },
  {
    value: "Female",
    name: "Female",
  },
  {
    value: "Prefer not to say",
    name: "Prefer not to say",
  },
];

export const contactCategoryData = [
  {
    value: "Home",
    name: "Home",
  },
  {
    value: "Office",
    name: "Office",
  },
  {
    value: "Others",
    name: "Others",
  },
];

export const emailReminderData = [
  {
    value: "Yes",
    name: "Yes",
  },
  {
    value: "No",
    name: "No",
  },
];

export const allergyCategoryData = [
  {
    value: "Drug",
    name: "Drug",
  },
  {
    value: "Intolerance",
    name: "Intolerance",
  },
  {
    value: "Enviornmental",
    name: "Enviornmental",
  },
  {
    value: "NKDA",
    name: "NKDA",
  },
  {
    value: "Other",
    name: "Other",
  },
];

export const severityData = [
  {
    value: "Unknown",
    name: "Unknown",
  },
  {
    value: "Mild",
    name: "Mild",
  },
  {
    value: "Moderate",
    name: "Moderate",
  },
  {
    value: "Severe",
    name: "Severe",
  },
];
