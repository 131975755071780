import * as Yup from "yup";

export const patientIntakeFormValidationSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("This is required field"),
  healthcare_number: Yup.string().required("This is required field"),
  canada_province: Yup.string().required("This is required field"),
  dob: Yup.string().required("This is required field"),
  new_born: Yup.string().required("This is required field"),
  first_name: Yup.string().required("This is required field"),
  last_name: Yup.string().required("This is required field"),
  sex: Yup.string().required("This is required field"),
  street_name: Yup.string().required("This is required field"),
  city: Yup.string().required("This is required field"),
  country: Yup.string().required("This is required field"),
  full_address_province: Yup.string().required("This is required field"),
  postal_code: Yup.string().required("This is required field"),
  phone_number: Yup.string().required("This is required field"),
  contact_category: Yup.string().required("This is required field"),
  primary: Yup.string().required("This is required field"),
  voicemail_consent: Yup.string().required("This is required field"),
  email_reminders: Yup.string().required("This is required field"),
  sms_reminders: Yup.string().required("This is required field"),
  do_you_have_any_allergies: Yup.string().required("This is required field"),
});
