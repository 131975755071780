export const patientIntakeFormInitialValues = {
  email: "",
  healthcare_number: "",
  canada_province: "",
  dob: "",
  new_born: "",
  first_name: "",
  last_name: "",
  preferred_name: "",
  middle_name: "",
  sex: "",
  street_name: "",
  city: "",
  country: "",
  full_address_province: "",
  postal_code: "",
  phone_number: "",
  extension: "",
  contact_category: "",
  primary: "",
  voicemail_consent: "",
  email_reminders: "",
  sms_reminders: "",
  emergency_phone: "",
  emergency_name: "",
  emergency_relationship: "",
  do_you_have_any_allergies: "",
  allergies_category: "",
  allergies_severity: "",
  confirmed_by_allergist: "",
};
