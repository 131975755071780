import { Home } from "../screens";
import { routesConstant } from "./routesConstant";

export const routes = [
  {
    url: routesConstant.HOME,
    element: <Home />,
    changefreq: "daily",
    priority: 1.0,
  },
];
