import React from "react";
// import React, { useRef } from "react";
import { useFormik } from "formik";
// import ReCAPTCHA from "react-google-recaptcha";
import styles from "./style.module.scss";
import { patientIntakeFormInitialValues } from "../../../../utils/formik/initialvalue";
import { patientIntakeFormValidationSchema } from "../../../../utils/formik/validationSchema";
import { Button, SectionTitle } from "../../../../styles/styledComponent";
import {
  allergyCategoryData,
  canadaProvinceData,
  contactCategoryData,
  emailReminderData,
  severityData,
  sexData,
} from "../../../../utils/constant";

const PatientIntakeForm = () => {
  // const recaptchaRef = useRef();
  const formik = useFormik({
    initialValues: patientIntakeFormInitialValues,
    validationSchema: patientIntakeFormValidationSchema,
  });

  console.log("is-valid", formik.isValid);
  console.log("is-dirty", formik.dirty);
  return (
    <div className={styles.formBlock}>
      <form
        // onSubmit={formik.handleSubmit}
        method="POST"
        name="patientIntakeForm"
        className="patientIntakeForm"
        netlify
        data-netlify="true"
        // data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="patientIntakeForm" />
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Email<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="email"
              {...formik.getFieldProps("email")}
              style={
                formik.touched.email && formik.errors.email
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.email && formik.errors.email && (
              <p className={styles.errortext}>{formik.errors.email}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Healthcare Number<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="text"
              {...formik.getFieldProps("healthcare_number")}
              style={
                formik.touched.healthcare_number &&
                formik.errors.healthcare_number
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.healthcare_number &&
              formik.errors.healthcare_number && (
                <p className={styles.errortext}>
                  {formik.errors.healthcare_number}
                </p>
              )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Province<span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("canada_province")}
              style={
                formik.touched.canada_province && formik.errors.canada_province
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              {canadaProvinceData.map((val, i) => (
                <option value={val.value}>{val.name}</option>
              ))}
            </select>
            {/* <input
              type="text"
              {...formik.getFieldProps("canada_province")}
              style={
                formik.touched.canada_province &&
                formik.errors.canada_province
                  ? { borderColor: "red" }
                  : null
              }
            /> */}
            {formik.touched.canada_province &&
              formik.errors.canada_province && (
                <p className={styles.errortext}>
                  {formik.errors.canada_province}
                </p>
              )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              DOB<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="date"
              {...formik.getFieldProps("dob")}
              style={
                formik.touched.dob && formik.errors.dob
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.dob && formik.errors.dob && (
              <p className={styles.errortext}>{formik.errors.dob}</p>
            )}
          </div>
          <div className={styles.fieldBox}>
            <label>
              New Born?<span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("new_born")}
              style={
                formik.touched.new_born && formik.errors.new_born
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              <option values="Yes">Yes</option>
              <option values="No">No</option>
            </select>
            {formik.touched.new_born && formik.errors.new_born && (
              <p className={styles.errortext}>{formik.errors.new_born}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              First Name<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="text"
              {...formik.getFieldProps("first_name")}
              style={
                formik.touched.first_name && formik.errors.first_name
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <p className={styles.errortext}>{formik.errors.first_name}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Last Name<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="text"
              {...formik.getFieldProps("last_name")}
              style={
                formik.touched.last_name && formik.errors.last_name
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <p className={styles.errortext}>{formik.errors.last_name}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>Preferred Name</label>
            <input type="text" {...formik.getFieldProps("preferred_name")} />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>Middle Name</label>
            <input type="text" {...formik.getFieldProps("middle_name")} />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Sex<span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("sex")}
              style={
                formik.touched.sex && formik.errors.sex
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              {sexData.map((val, i) => (
                <option value={val.value}>{val.name}</option>
              ))}
            </select>
            {formik.touched.sex && formik.errors.sex && (
              <p className={styles.errortext}>{formik.errors.sex}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <SectionTitle className={styles.formSectionTitle}>
            Full Address
          </SectionTitle>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Street Name<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="text"
              {...formik.getFieldProps("street_name")}
              style={
                formik.touched.street_name && formik.errors.street_name
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.street_name && formik.errors.street_name && (
              <p className={styles.errortext}>{formik.errors.street_name}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              City<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="text"
              {...formik.getFieldProps("city")}
              style={
                formik.touched.city && formik.errors.city
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.city && formik.errors.city && (
              <p className={styles.errortext}>{formik.errors.city}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Country<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="text"
              {...formik.getFieldProps("country")}
              style={
                formik.touched.country && formik.errors.country
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.country && formik.errors.country && (
              <p className={styles.errortext}>{formik.errors.country}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Province<span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("full_address_province")}
              style={
                formik.touched.full_address_province &&
                formik.errors.full_address_province
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              {canadaProvinceData.map((val, i) => (
                <option value={val.value}>{val.name}</option>
              ))}
            </select>
            {formik.touched.full_address_province &&
              formik.errors.full_address_province && (
                <p className={styles.errortext}>
                  {formik.errors.full_address_province}
                </p>
              )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Postal Code<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="text"
              {...formik.getFieldProps("postal_code")}
              style={
                formik.touched.postal_code && formik.errors.postal_code
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.postal_code && formik.errors.postal_code && (
              <p className={styles.errortext}>{formik.errors.postal_code}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Phone Number<span className={styles.requiredIcon}>*</span>
            </label>
            <input
              type="text"
              {...formik.getFieldProps("phone_number")}
              style={
                formik.touched.phone_number && formik.errors.phone_number
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.phone_number && formik.errors.phone_number && (
              <p className={styles.errortext}>{formik.errors.phone_number}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>Extension</label>
            <input type="text" {...formik.getFieldProps("extension")} />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Category<span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("contact_category")}
              style={
                formik.touched.contact_category &&
                formik.errors.contact_category
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              {contactCategoryData.map((val, i) => (
                <option value={val.value}>{val.name}</option>
              ))}
            </select>
            {formik.touched.contact_category &&
              formik.errors.contact_category && (
                <p className={styles.errortext}>
                  {formik.errors.contact_category}
                </p>
              )}
          </div>
          <div className={styles.fieldBox}>
            <label>
              Primary?<span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("primary")}
              style={
                formik.touched.primary && formik.errors.primary
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              <option values="Yes">Yes</option>
              <option values="No">No</option>
            </select>
            {formik.touched.primary && formik.errors.primary && (
              <p className={styles.errortext}>{formik.errors.primary}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Voicemail Consent<span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("voicemail_consent")}
              style={
                formik.touched.voicemail_consent &&
                formik.errors.voicemail_consent
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              <option values="Yes">Yes</option>
              <option values="No">No</option>
            </select>
            {formik.touched.voicemail_consent &&
              formik.errors.voicemail_consent && (
                <p className={styles.errortext}>
                  {formik.errors.voicemail_consent}
                </p>
              )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Email Reminders?
              <span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("email_reminders")}
              style={
                formik.touched.email_reminders && formik.errors.email_reminders
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              {emailReminderData.map((val, i) => (
                <option value={val.value}>{val.name}</option>
              ))}
            </select>
            {formik.touched.email_reminders &&
              formik.errors.email_reminders && (
                <p className={styles.errortext}>
                  {formik.errors.email_reminders}
                </p>
              )}
          </div>
          <div className={styles.fieldBox}>
            <label>
              SMS Reminders?
              <span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("sms_reminders")}
              style={
                formik.touched.sms_reminders && formik.errors.sms_reminders
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              {emailReminderData.map((val, i) => (
                <option value={val.value}>{val.name}</option>
              ))}
            </select>
            {formik.touched.sms_reminders && formik.errors.sms_reminders && (
              <p className={styles.errortext}>{formik.errors.sms_reminders}</p>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>Emergency Phone</label>
            <input type="text" {...formik.getFieldProps("emergency_phone")} />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>Emergency Name</label>
            <input type="text" {...formik.getFieldProps("emergency_name")} />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>Emergency Relationship</label>
            <input
              type="text"
              {...formik.getFieldProps("emergency_relationship")}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <SectionTitle className={styles.formSectionTitle}>
            Allergy Information
          </SectionTitle>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldBox}>
            <label>
              Do you have any Allergies ?
              <span className={styles.requiredIcon}>*</span>
            </label>
            <select
              {...formik.getFieldProps("do_you_have_any_allergies")}
              style={
                formik.touched.do_you_have_any_allergies &&
                formik.errors.do_you_have_any_allergies
                  ? { borderColor: "red" }
                  : null
              }
              defaultValue={""}
            >
              <option value={""} disabled>
                Select
              </option>
              <option values="Yes">Yes</option>
              <option values="No">No</option>
            </select>
            {formik.touched.do_you_have_any_allergies &&
              formik.errors.do_you_have_any_allergies && (
                <p className={styles.errortext}>
                  {formik.errors.do_you_have_any_allergies}
                </p>
              )}
          </div>
        </div>
        {formik.values.do_you_have_any_allergies === "Yes" && (
          <>
            <div className={styles.fieldContainer}>
              <div className={styles.fieldBox}>
                <label>
                  Category<span className={styles.requiredIcon}>*</span>
                </label>
                <select
                  {...formik.getFieldProps("allergies_category")}
                  style={
                    formik.touched.allergies_category &&
                    formik.errors.allergies_category
                      ? { borderColor: "red" }
                      : null
                  }
                  defaultValue={""}
                >
                  <option value={""} disabled>
                    Select
                  </option>
                  {allergyCategoryData.map((val, i) => (
                    <option value={val.value}>{val.name}</option>
                  ))}
                </select>
                {formik.touched.allergies_category &&
                  formik.errors.allergies_category && (
                    <p className={styles.errortext}>
                      {formik.errors.allergies_category}
                    </p>
                  )}
              </div>
              <div className={styles.fieldBox}>
                <label>Severity</label>
                <select
                  {...formik.getFieldProps("allergies_severity")}
                  defaultValue={""}
                >
                  <option value={""} disabled>
                    Select
                  </option>
                  {severityData.map((val, i) => (
                    <option value={val.value}>{val.name}</option>
                  ))}
                </select>
                {formik.touched.allergies_severity &&
                  formik.errors.allergies_severity && (
                    <p className={styles.errortext}>
                      {formik.errors.allergies_severity}
                    </p>
                  )}
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.fieldBox}>
                <label>
                  Confirmed By Allergist?
                  <span className={styles.requiredIcon}>*</span>
                </label>
                <select
                  {...formik.getFieldProps("confirmed_by_allergist")}
                  style={
                    formik.touched.confirmed_by_allergist &&
                    formik.errors.confirmed_by_allergist
                      ? { borderColor: "red" }
                      : null
                  }
                  defaultValue={""}
                >
                  <option value={""} disabled>
                    Select
                  </option>
                  <option values="Yes">Yes</option>
                  <option values="No">No</option>
                </select>
                {formik.touched.confirmed_by_allergist &&
                  formik.errors.confirmed_by_allergist && (
                    <p className={styles.errortext}>
                      {formik.errors.confirmed_by_allergist}
                    </p>
                  )}
              </div>
            </div>
          </>
        )}
        <div className={styles.fieldContainer}>
          <Button
            className={
              !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
            }
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PatientIntakeForm;
