import { Routes, Route } from "react-router-dom";
import "./App.scss";
import { routes } from "./utils/routes";

function App() {
  return (
    <main>
      <Routes>
        {routes.map((data) => (
          <Route path={data.url} element={data.element} />
        ))}
      </Routes>
    </main>
  );
}

export default App;
